import React from 'react'
import { DotLoader } from 'react-spinners'

import './LoadingDisplay.css'

function LoadingDisplay({message}) {
    return (
        <div className='loading-page-main-div'>
            <DotLoader color='white' />
            <p>{message ?? 'Loading'}</p>
        </div>
    )
}

export default LoadingDisplay