const portuguese = {
	common: {
		enter: "Entrar",
		my_profile: "Meu Perfil",
		leave: "Sair",
		submit: "Submeter",
		cancel: "Cancelar",
		view: "Ver",
		loading_tables: "A carregar as tabelas",
	},
	pass_reset: {
		redirect: "Irá ser redirecionado para a pagina de login dentro de 5 segundos",
		title: "Defina a sua nova palavra passe aqui",
	},
	errors: {
		timeout: {
			message: "Ocorreu um erro ao fazer o seu pedido",
			description: "Não foi possível comunicar com o servidor, por favor tente mais tarde",
		},
	},
	login: {
		forgotPass: "Esqueceu-se da sua palavra passe? Clique aqui",
		remember_me: {
			label: "Lembrar-me neste dispositivo",
			tooltip:
				"Ao selecionar esta opção, na sua proxima visita à plataforma, não irá precisar de introduzir as suas credenciais novamente (E-mail and Password)",
		},
	},
	inputs: {
		mandatory: "Campo obrigatório",
		name: {
			title: "Nome",
			errors: {
				invalid: "Nome inválido",
			},
		},
		email: {
			title: "Email",
			errors: {
				invalid: "Email inválido",
			},
		},
		message: {
			title: "Mensagem",
			errors: {
				invalid: "Mensagem demasiado curta",
			},
		},
		password: {
			title: "Palavra Passe",
			confirm: "Confirme a nova Palavra passe",
			new: "Nova palavra passe",
			current: "Palavra passe atual",
			errors: {
				match: "Palavras passe não coincidem",
				invalid: "Password tem que conter pelo menos 8 caracteres com maiúsculas, minúsculas e números",
			},
			helper_text: {
				confirm: "Confirme a sua nova palavra passe",
				verify: "introduza a sua palavra passe para confirmar a sua identidade",
			},
		},
	},
	profile: {
		last_login: "Ultimo login",
		acc_created: "Conta criada em",
		edit_info: {
			title: "Editar informação",
			no_change: "Não foram detetadas alterações",
		},
		change_pass: {
			title: "Alterar palavra passe",
		},
		image: {
			upload: "Clique ou arraste para alterar a imagem",
			remove: "Remover imagem",
			too_large: "Imagem demasiado grande, limite de 1mb",
			wrong_type: "Tipo de ficheiro não aceite",
		},
	},
	qrcode_dialog: {
		title: "Código QR",
		buttons: {
			1: "URL",
			2: "Copiar Código QR",
		},
		response: {
			ok: "Código QR copiado",
			no: "Não foi possível copiar o código QR",
			error: "O seu browser não suporta esta funcionalidade",
		},
	},
	share_dialog: {
		response: {
			ok: "URL copiado para a sua área de transferência",
			error: "Não foi possível copiar o URL para a sua área de transferência",
		},
	},
	tooltips: {
		share_project: "Partilhar projeto",
		copy_url: "Copiar URL",
		view_pdfs: "Ver PDF's",
		copy_qr: "Copiar imagem do código QR para área de transferência",
		open_url: "Abrir URL num novo separador",
	},
	footer: {
		tnc: "Termos e Condições",
		cookies: "Política de Cookies",
		privacy: "Política de Privacidade",
		contacts: "Contactos",
		show: "Mostrar Políticas",
		hide: "Esconder Políticas",
	},
	dashboard: {
		tab1: "Projetos por empresa",
		tab2: "projetos partilhados comigo",
		filters: {
			filter1: "Ano",
			filter2: "Empresa",
			search: "Procurar por nome de projeto",
		},
		tables: "Ver Tabelas",
		no_projects_shared: "Não existem projetos partilhados consigo",
	},
	forgot_pass_dialog: {
		title: "Recuperar Palavra Passe",
		text: "Indique o seu email registado para poder recuperar a sua password",
		email_not_valid: "não é um email válido",
	},
	tables: {
		created: "Criada em",
		modified: "Modificada em",
		name: "Nome da tabela",
		type: "Tipo de table",
		export_button: "Exportar Dados",
		save_button: "Guardar tabela",
	},
	project: {
		not_found: "Projeto não encontrado",
	},
	filters: {
		show: "Mostrar Filtros",
		hide: "Esconder Filtros",
	},
	contacts: {
		title: "Precisa de ajuda?",
		subtitle:
			"Utilize este formulário para nos enviar uma mensagem ou use a informação a baixo para entrar em contacto connosco",
		phone: "Contacto Telefónico (09h-18h GMT+{{gmt}})",
		send: "Enviar mensagem",
	},
};

export default portuguese;
