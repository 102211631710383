import React from 'react'
import DialogPrefab from '../DialogPrefab'
import { useTranslation } from 'react-i18next'
import './FooterDialogs.css'

function Tnc(props) {
    const { t, i18n } = useTranslation()

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            title={t('footer.tnc')}
            maxWidth='lg'
            fullScreen={window.innerWidth < 960}
        >
            {i18n.language === 'pt' ?
                <div className='text-main-div'>
                    <h3>Bem-vindo à Faunalab VR!</h3><br />

                    <p>Estes termos e condições descrevem as regras e regulamentos para a utilização do Website da Faunalab VR, localizado em https://faunalabvr.com.</p>

                    <p>Ao aceder a este website, assumimos que aceita estes termos e condições. Não continue a utilizar a Faunalab VR se não concordar em aceitar todos os termos e condições indicados nesta página.</p>
                    <p>A seguinte terminologia aplica-se a estes Termos e Condições, Declaração de Privacidade e Aviso de Isenção de Responsabilidade e a todos os Acordos: "Cliente", "Você" e "Seu" referem-se a si, a pessoa que faz o login neste website e cumpre com os termos e condições da Empresa. "A Empresa", "Nós próprios", "Nós", "Nosso" e "Nós", refere-se à nossa Empresa. "Partido", "Partes", ou "Nós", refere-se tanto ao Cliente como a nós próprios. Todos os termos referem-se à oferta, aceitação e consideração do pagamento necessário para empreender o processo da nossa assistência ao Cliente da forma mais apropriada para o objectivo expresso de satisfazer as necessidades do Cliente no que respeita à prestação dos serviços declarados da Empresa. Qualquer uso da terminologia acima ou outras palavras no singular, plural, capitalização e/ou ele/ela ou eles, são considerados como intercambiáveis e, portanto, como referindo-se ao mesmo.</p>

                    <h1>Cookies</h1>

                    <p>Utilização de cookies. Ao aceder à Faunalab VR, concordou em utilizar cookies de acordo com a Política de Privacidade da Faunalab VR.</p>

                    <p>A maioria dos websites interativos utiliza cookies para nos permitir recuperar os dados do utilizador para cada visita. Os cookies são utilizados pelo nosso website para permitir a funcionalidade de certas áreas, de modo a facilitar a visita das pessoas que visitam o nosso website. Alguns dos nossos parceiros afiliados/parceiros publicitários podem também utilizar cookies.</p>

                    <h1>Licença</h1>

                    <p>Salvo indicação em contrário, a Faunalab VR detém os direitos de propriedade intelectual de todo o material na faunalab VR. Todos os direitos de propriedade intelectual são reservados. Poderá aceder a estes na Faunalab VR para seu uso pessoal, sujeito às restrições estabelecidas nestes termos e condições.</p>

                    <p>Não deve fazê-lo:</p>
                    <ul>
                        <li>Publicar material da Faunalab VR</li>
                        <li>Venda, aluguer ou sub-licenciamento de material da Faunalab VR</li>
                        <li>Reproduzir, duplicar ou copiar material da Faunalab VR</li>
                        <li>Redistribuir conteúdo de Faunalab VR</li>
                    </ul>

                    <h1>Hiperligação ao nosso Conteúdo</h1>

                    <p>As seguintes organizações podem criar um link para o nosso sítio web sem aprovação prévia por escrito:</p>

                    <ul>
                        <li>Agências governamentais;</li>
                        <li>Motores de pesquisa;</li>
                    </ul>

                    <h1>iFrames</h1>

                    <p>Sem aprovação prévia e autorização por escrito, não poderá criar frames em torno das nossas páginas Web que alterem de forma alguma a apresentação visual ou a aparência do nosso Website.</p>

                    <h1>A sua privacidade</h1>

                    <p>Por favor leia a Política de Privacidade</p>

                    <h1>Declaração de exoneração de responsabilidade</h1>

                    <p>Na medida máxima permitida pela lei aplicável, excluímos todas as representações, garantias e condições relacionadas com o nosso website e a utilização deste website. Nada nesta declaração de exoneração de responsabilidade o fará:</p>

                    <ul>
                        <li>limitará ou excluirá a nossa ou a sua responsabilidade por fraude ou deturpação fraudulenta;</li>
                        <li>limitará qualquer responsabilidade nossa ou do utilizador de qualquer forma que não seja permitida pela lei aplicável;</li>
                        <li>excluir quaisquer das nossas ou das suas responsabilidades que não possam ser excluídas ao abrigo da lei aplicável.</li>
                    </ul>
                    <br />
                    <p>As limitações e proibições de responsabilidade estabelecidas nesta secção e em qualquer outra parte desta exoneração de responsabilidade: (a) estão sujeitas ao parágrafo anterior; e (b) regem todas as responsabilidades decorrentes da exoneração de responsabilidade, incluindo responsabilidades decorrentes de contrato, delito civil e por violação do dever estatutário.</p>

                    <br />
                    <p>Desde que o website e as informações e serviços no website sejam fornecidos gratuitamente, não seremos responsáveis por qualquer perda ou dano de qualquer natureza.</p>

                    <h1>Consentimento</h1>

                    <p>Ao utilizar o nosso website, o utilizador consente com os nossos termos e condições</p>
                </div>
                :
                <div className='text-main-div'>
                    <h3>Welcome at Faunalab VR!</h3><br />

                    <p>These terms and conditions describe the rules and regulations for using the Faunalab VR Website, located at https://faunalabvr.com.</p>

                    <p>By accessing this website, we assume that you accept these terms and conditions. Do not continue to use Faunalab VR if you do not agree to accept all the terms and conditions indicated on this page.</p>
                    <br />
                    <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Customer", "You" and "Your" refers to you, the person who logs in on this website and complies with the Company's terms and conditions. "The Company", "Ourselves", "Us", "Our" and "Us" refers to our Company. "Party", "Parties", or "We", refers to both the Customer and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Customer in the most appropriate way for the express purpose of meeting the Customer's needs in relation to the provision of the Company's stated services. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they are considered interchangeable and therefore referring to the same.</p>

                    <h1>Cookies</h1>

                    <p>Use of cookies. By accessing Faunalab VR, you agreed to use cookies in accordance with the Faunalab VR Privacy Policy.</p>

                    <p>Most interactive websites use cookies to allow us to retrieve user data for each visit. Cookies are used by our website to allow the functionality of certain areas, in order to facilitate the visit of people who visit our website. Some of our affiliate partners/advertising partners may also use cookies.</p>

                    <h1>License</h1>

                    <p>Unless otherwise noted, Faunalab VR owns the intellectual property rights to all material on Faunalab VR. All intellectual property rights are reserved. You may access these at Faunalab VR for your personal use, subject to the restrictions set out in these terms and conditions.</p>

                    <p>You must not do this:</p>
                    <ul>
                        <li>Publish material from Faunalab VR</li>
                        <li>Sale, rental or sub-licensing of Faunalab VR material</li>
                        <li>Reproduce, duplicate or copy material from Faunalab VR</li>
                        <li>Redistribute Faunalab VR content</li>
                    </ul>

                    <h1>Hyperlink to our Content</h1>

                    <p>The following organizations may link to our website without prior written approval:</p>

                    <ul>
                        <li>Government agencies;</li>
                        <li>Search engines;</li>
                    </ul>

                    <h1>IFrames</h1>

                    <p>Without prior approval and written authorization, you may not create frames around our web pages that alter the visual presentation or appearance of our website in any way.</p>

                    <h1>Your Privacy</h1>

                    <p>Please read the Privacy Policy</p>

                    <h1>Disclaimer of Liability</h1>

                    <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>

                    <ul>
                        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                        <li>limit any of our or your liability in any way not permitted by applicable law;</li>
                        <li>exclude any of our or your liabilities that cannot be excluded under applicable law.</li>
                    </ul>
                    <br />
                    <p>The limitations and prohibitions of liability set out in this section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising from the disclaimer, including liabilities arising in contract, tort and breach of statutory duty.</p>

                    <br />
                    <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any kind.</p>

                    <h1>Consent</h1>

                    <p>By using our website, the user consents to our terms and conditions</p>
                </div>
            }
        </DialogPrefab>
    )
}

export default Tnc