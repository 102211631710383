import React, { useEffect, useState } from "react";
import Filters from "../Filters/Filters";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FilterList, TableView } from "@mui/icons-material";
import ProjectsContainer from "./ProjectsContainer";
import useDialog from "../../Hooks/useDialog";

import "./ProjectsList.css";

function ProjectsList(props) {
	const [data, setData] = useState([]);
	const [groupedData, setGroupedData] = useState([]);
	const { openTablesDialog } = useDialog();
	const [showFilter, setShowFilter] = useState(false); //for mobile
	const { t } = useTranslation();

	useEffect(() => {
		const groupedByCompany = [];
		if (data.length > 0) {
			// biome-ignore lint/complexity/noForEach: <explanation>
			data.forEach(proj => {
				const companyIndex = groupedByCompany.findIndex(el => el.company.id === proj.company.id);
				if (companyIndex !== -1) {
					groupedByCompany[companyIndex].company.projects.push(proj);
				} else {
					groupedByCompany.push({ company: { ...proj.company, projects: [proj] } });
				}
			});
			setGroupedData(groupedByCompany);
		}
	}, [data]);

	return (
		<div className="projects-list-main-div">
			<Filters
				data={props.projects}
				setData={data => setData(data)}
				view="projects"
				show={showFilter}
				setShow={val => setShowFilter(val)}
			/>
			<div className={`show-filters-button${!showFilter ? " show" : ""}`}>
				<Button
					aria-label="filters"
					variant="contained"
					color="secondary"
					size="small"
					fullWidth
					startIcon={<FilterList htmlColor="white" />}
					onClick={() => setShowFilter(!showFilter)}
				>
					<p style={{ color: "white" }}>{t("filters.show")}</p>
				</Button>
			</div>
			<div className="projects-content">
				{groupedData.length === 0 && <p style={{ color: "white", fontSize: "22px" }}>No projects to display</p>}
				{groupedData.map(group => {
					return (
						<div key={group.company.id} className="projects-content-company-group">
							<div className="projects-content-company-info">
								<div className="projects-content-image-div">
									{group.company.image && (
										<img
											alt={group.company.name}
											src={`${process.env.REACT_APP_SERVER_URL}/images/companies/${group.company.image}`}
										/>
									)}
									<p>{group.company.name}</p>
								</div>
								{group.company.tables.length > 0 && (
									<div className="projects-content-actions-div">
										<Button
											aria-label="tables"
											variant="contained"
											color="secondary"
											size="small"
											startIcon={<TableView htmlColor="white" />}
											onClick={() => openTablesDialog(group.company)}
										>
											<p style={{ color: "white" }}>{t("dashboard.tables")}</p>
										</Button>
									</div>
								)}
							</div>
							<ProjectsContainer projects={group.company.projects} />
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default ProjectsList;
