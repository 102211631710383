import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import ModalProvider from 'mui-modal-provider'

import { SocketContext, socket } from './Context/socket/socket'
import { SnackbarProvider } from 'notistack'
import { UserProvider } from './Context/user'


import Login from './Pages/Login'
import ResetPass from './Pages/ResetPass'
import ProjectsViewPage from './Pages/ProjectsViewPage'
import Footer from './Panels/Footer/Footer';

import './lang/i18n'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<UserProvider>
		<BrowserRouter>
			<SnackbarProvider maxSnack={4}>
				<ModalProvider>
					<SocketContext.Provider value={socket}>
						<Routes>
							<Route path='/login' element={<Login />} />
							<Route path='/password-reset' element={<ResetPass />} />
							<Route path='/projects' element={<ProjectsViewPage />} />
							<Route path='/*' element={<App />} />
							<Route path='*' element={<Navigate to='/login' />} />
						</Routes>
						<Footer />
					</SocketContext.Provider>
				</ModalProvider>
			</SnackbarProvider>
		</BrowserRouter>
	</UserProvider>
);

