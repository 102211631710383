import React, { useEffect, useState, useContext } from 'react'
import { useUserInfo } from '../../Hooks/useUser'
import { Avatar, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useToken from '../../Hooks/useToken'
import ErrorDialog from '../../Components/Dialogs/ErrorDialog'
import MyProfile from '../../Components/Dialogs/Profile/MyProfile'
import { SocketContext } from '../../Context/socket/socket'
import CustomMenu from '../../Components/Misc/CustomMenu/CustomMenu'
import { useTranslation } from 'react-i18next'

import Logo from '../../Assets/Logos/logo_horizontal.svg'
import LogoMobile from '../../Assets/Logos/fauna.svg'

import './TopPanel.css'

const errorDialogInfo = {
    status: 404,
    statusText: 'Disconnected from server',
    data: 'You got disconnected from the server because the server may be offline. You will be redirected to the login page in 5 seconds',
}

function TopPanel() {
    const { userInfo } = useUserInfo()

    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate()
    const { setToken } = useToken()
    const [showErrorDialog, setShowErrorDialog] = useState(false)
    const [openProfile, setOpenProfile] = useState(false)

    const socket = useContext(SocketContext)

    let timer;

    useEffect(() => {
        if (userInfo.id) {
            socket.on('connect_error', (args) => onConnectionError(args))
        }

        return () => {
            socket.off('connect_error', onConnectionError)
        }

    }, [userInfo])


    const logoutUser = () => {
        clearTimeout(timer)
        setToken(null)
        socket.disconnect()
        navigate('/login')
    }

    const onConnectionError = (args) => {
        if (timer) return; //previne a criação de múltiplos timeouts

        timer = setTimeout(() => {
            logoutUser(false)
        }, 5000)
        setShowErrorDialog(true)
    }

    return (
        <>
            <div className='top-bar-main-div'>
                <div className='top-bar-logo-div'>
                    <img alt='fauna logo' src={Logo} className='top-bar-logo' />
                    <img alt='fauna logo' src={LogoMobile} className='top-bar-logo-mobile' />
                </div>
                <div className='top-panel-user-display' onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <Avatar alt={userInfo.name} src={userInfo.portrait ? `${process.env.REACT_APP_SERVER_URL}/images/appUsers/${userInfo.portrait}` : null} sx={{ width: '35px', height: '35px', margin: '5px 10px 5px 5px' }} />
                    <p>{userInfo.name}</p>
                </div>
            </div>
            <UserMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} logout={logoutUser} openProfile={() => setOpenProfile(true)} />
            <ErrorDialog open={showErrorDialog} close={() => setShowErrorDialog(false)} info={errorDialogInfo} ocb={logoutUser} />
            <MyProfile open={openProfile} close={() => setOpenProfile(false)} />
        </>
    )
}

export default TopPanel

const UserMenu = (props) => {
    const { t } = useTranslation()

    return (
        <CustomMenu
            id='profile-menu'
            anchorEl={props.anchorEl}
            open={Boolean(props.anchorEl)}
            onClose={() => props.setAnchorEl(null)}
        >
            <MenuItem onClick={() => { props.setAnchorEl(null); props.openProfile() }}>
                <p>{t('common.my_profile')}</p>
            </MenuItem>
            <MenuItem onClick={props.logout}>
                <p>{t('common.leave')}</p>
            </MenuItem>
        </CustomMenu>
    )
}