import React, { useState, useEffect } from "react";
import { checkProject } from "../API/Requests";
import { useTranslation } from "react-i18next";

import Logo from "../Assets/Logos/logo_vertical.svg";

import "./css/ProjectsViewPage.css";

function ProjectsViewPage() {
	// const [projectUrl, setProjectUrl] = useState("");
	// const [found, setFound] = useState(true);
	// const { t } = useTranslation();

	// useEffect(() => {
	// 	const urlParams = new URLSearchParams(window.location.search);
	// 	const projectName = urlParams.get("name");

	// 	if (projectName) {
	// 		checkProject(projectName).then(
	// 			res => {
	// 				setProjectUrl(`${process.env.REACT_APP_SERVER_URL}/project/${projectName}/index.htm`);
	// 				document.title = res.data.name;
	// 			},
	// 			err => {
	// 				setFound(false);
	// 				console.error(err);
	// 			},
	// 		);
	// 	} else {
	// 		setFound(false);
	// 	}
	// }, []);

	return (
		<div className="projects-view-main-div">
			{/* {found ? (
				<iframe
					src={projectUrl}
					referrerPolicy="no-referrer"
					allowFullScreen={true}
					title={"test"}
					style={{ width: "100%", height: "100%", border: "none" }}
				/>
			) : (
				<div className="project-not-found-div">
					<div className="login-page-logo-div">
						<img alt="logo" src={Logo} className="login-page-logo" />
					</div>
					<p className="not-found">{t("project.not_found")}</p>
				</div>
			)} */}
		</div>
	);
}

export default ProjectsViewPage;
