import React, { useRef, useState } from "react";
import DialogPrefab from "../DialogPrefab";
import { QRCode as QR } from "react-qrcode-logo";
import { openInNewTab } from "../../../utils";
import { Link, ContentCopy } from "@mui/icons-material";
import { Button } from "@mui/material";
import useGlobalSnackbar from "../../../Hooks/useGlobalSnackbar";
import CustomTooltip from "../../Misc/CustomTooltip";
import { useTranslation } from "react-i18next";

import FirefoxCompatibleLogoBlack from "../../../Assets/Logos/fauna_small_firefox_black.svg";
import FirefoxCompatibleLogo from "../../../Assets/Logos/fauna_small_firefox.svg";

import "./QRCodeDialog.css";

function QRCodeDialog(props) {
	const canvasRef = useRef(null);
	const { showSnackbar } = useGlobalSnackbar();
	const { t } = useTranslation();
	const [contrast, setContrast] = useState(false);

	const copyQRImageToClipboard = () => {
		if (canvasRef.current) {
			canvasRef.current.canvas.current.toBlob(blob => {
				try {
					navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]).then(
						() =>
							showSnackbar({
								message: t("qrcode_dialog.response.ok"),
								variant: "default",
								persist: false,
								autoHideDuration: 2000,
							}),
						err => {
							showSnackbar({
								message: t("qrcode_dialog.response.no"),
								variant: "error",
								persist: false,
								autoHideDuration: 2000,
							});
							console.log(err);
						},
					);
				} catch (err) {
					showSnackbar({
						message: t("qrcode_dialog.response.error"),
						variant: "error",
						persist: false,
						autoHideDuration: 2000,
					});
					console.log(err);
				}
			});
		}
	};

	return (
		<DialogPrefab open={props.open} close={props.close} maxWidth="xs" title={t("qrcode_dialog.title")}>
			<div className="qrcode-main-div">
				{contrast ? (
					<QR
						value={props.url}
						size={200}
						// logoImage={FirefoxCompatibleLogoBlack}
						// removeQrCodeBehindLogo={true}
						// logoWidth={60}
						// logoHeight={15}
						qrStyle="dots"
						eyeRadius={5}
						bgColor="white"
						fgColor="black"
					/>
				) : (
					<QR
						value={props.url}
						size={200}
						// logoImage={FirefoxCompatibleLogo}
						// removeQrCodeBehindLogo={true}
						// logoWidth={60}
						// logoHeight={15}
						qrStyle="dots"
						eyeRadius={5}
						bgColor="#0000"
						fgColor="white"
					/>
				)}
				<div className="qrcode-dialog-actions">
					<CustomTooltip title={t("tooltips.open_url")}>
						<Button
							aria-label="link"
							variant="contained"
							color="secondary"
							startIcon={<Link htmlColor="white" />}
							onClick={() => openInNewTab(props.url)}
						>
							<p style={{ color: "white" }}>{t("qrcode_dialog.buttons.1")}</p>
						</Button>
					</CustomTooltip>
					<CustomTooltip title={t("tooltips.copy_qr")}>
						<Button
							aria-label="share"
							variant="contained"
							color="secondary"
							startIcon={<ContentCopy htmlColor="white" />}
							onClick={copyQRImageToClipboard}
						>
							<p style={{ color: "white" }}>{t("qrcode_dialog.buttons.2")}</p>
						</Button>
					</CustomTooltip>
				</div>
				{/* Este QR code é só para poder ser copiado com background */}
				<div style={{ display: "none" }}>
					<QR
						ref={canvasRef}
						value={props.url}
						size={200}
						// logoImage={FirefoxCompatibleLogoBlack}
						// removeQrCodeBehindLogo={true}
						// logoWidth={60}
						// logoHeight={15}
						qrStyle="dots"
						eyeRadius={5}
					/>
				</div>
			</div>
		</DialogPrefab>
	);
}

export default QRCodeDialog;
