import React from 'react'
import { Search, Close } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

import './SearchInput.css'

function SearchInput({ value, onChange, ...props }) {

    return (
        <div className='search-input-main-div'>
            <div className='search-input-icon'>
                <Search htmlColor='white' />
            </div>
            <input
                className='search-input-input'
                type='text'
                list='search-data-list'
                value={value}
                maxLength={40}
                onChange={(e) => onChange(e.target.value)}
                {...props}
            />
            {value.length > 0 &&
                <Tooltip title='Apagar'>
                    <div className='search-input-close'>
                        <Close htmlColor='white' onClick={() => onChange('')} />
                    </div>
                </Tooltip>
            }
        </div>
    )
}

export default SearchInput