import React from 'react'
import { useTranslation } from 'react-i18next'

import ptFlag from '../../../Assets/Flags/pt.svg'
import enFlag from '../../../Assets/Flags/uk.svg'

import './LanguageChanger.css'

function LanguageChanger() {
    const {i18n} = useTranslation()

    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
    }

    return (
        <div className='language-changer-div'>
            <div className={`language-menu-item${i18n.language.includes('pt') ? ' selected' : ' unselected'}`} onClick={() => changeLanguage('pt')}>
                <img alt='portuguese' src={ptFlag} />
                {/* <p>Português</p> */}
            </div>
            <div className={`language-menu-item${i18n.language.includes('en') ? ' selected' : ' unselected'}`} onClick={() => changeLanguage('en')}>
                <img alt='english' src={enFlag} />
                {/* <p>English</p> */}
            </div>
        </div>
    )
}

export default LanguageChanger