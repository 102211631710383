import React, { useState } from 'react'
import Filters from '../Filters/Filters'
import ProjectCard from '../Cards/ProjectCard'
import { Button } from '@mui/material'
import { FilterList } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import './SharedProjects.css'

function SharedProjects(props) {
    const [data, setData] = useState([])
    const [showFilter, setShowFilter] = useState(false) //for mobile
    const { t } = useTranslation()

    return (
        <div className='shared-projects-main-div'>
            <Filters data={props.projects} setData={(data) => setData(data)} view='shared' show={showFilter} setShow={(val) => setShowFilter(val)} />
            <div className={`show-filters-button${!showFilter ? ' show' : ''}`}>
                <Button
                    aria-label='filters'
                    variant='contained'
                    color='secondary'
                    size='small'
                    fullWidth
                    startIcon={<FilterList htmlColor='white' />}
                    onClick={() => setShowFilter(!showFilter)}
                >
                    <p style={{ color: 'white' }}>{t('filters.show')}</p>
                </Button>
            </div>
            <div className='shared-projects-content'>
                {data.length === 0 &&
                    <p style={{color: 'white', fontSize:'22px'}}>{t('dashboard.no_projects_shared')}</p>
                }
                {data.map(proj => (
                    <ProjectCard project={proj} key={proj.id} />
                ))}
            </div>
        </div>
    )
}

export default SharedProjects