import { useEffect, useContext, useState, useMemo } from 'react'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'

import { useUserInfo } from './Hooks/useUser'
import useToken from './Hooks/useToken'
import { getEntity, verifyUser } from './API/Requests'
import { SocketContext } from './Context/socket/socket'

import TopPanel from './Panels/TopPanel/TopPanel'
import Dashboard from './Pages/Dashboard'

import LoadingDisplay from './Components/Misc/LoadingDisplay'

import { createTheme, ThemeProvider } from '@mui/material';

import './App.css';

function App() {
	const [ready, setReady] = useState(false)

	const { token, setToken } = useToken();
	const { setUserInfo } = useUserInfo()

	const socket = useContext(SocketContext)
	const navigate = useNavigate()

	useEffect(() => {
		socket.on('ready', () => getUser())

		return () => {
			socket.off('ready', getUser)
		}
	}, [])

	useEffect(() => {
		if (!token) {
			if (localStorage.getItem('remember_token') && localStorage.getItem('id')) {
				console.log('here')
				verifyUser(localStorage.getItem('remember_token'), localStorage.getItem('id')).then(res => {
					setToken({ token: res.headers.key })
				}, err => {
					console.error(err)
					navigate('/login', { replace: true })
				})
				return;
			}

			navigate('/login', { replace: true })
			return;
		}
		connectSocket()

	}, [token])

	const connectSocket = async () => {
		if (socket.connected) {
			getUser();
			return;
		}
		socket.auth = { uuid: localStorage.getItem('id'), token: token }
		socket.connect();
	}

	const getUser = async () => {
		if (localStorage.getItem('id')) {
			await getEntity('app_users', localStorage.getItem('id')).then(res => {
				setUserInfo({ ...res.data, token })
				setReady(true)
			}, err => {
				console.log(err)
				navigate('/login', { replace: true })
			})
		} else {
			navigate('/login', { replace: true })
		}
	}

	const tableTheme = useMemo(
		() =>
			createTheme({
				palette: {
					primary: {
						main: '#448aff',
					},
					secondary: {
						main: '#656aa9'
					},
				},
				components: {
					MuiTab: {
						styleOverrides: {
							root: {
								"&.Mui-selected": {
									color: 'white',
									borderRadius: "25px"
								},
								color: '#ffffff57'
							}
						}
					}
				}
			}),
		[],
	);

	return (
		ready ?
			<ThemeProvider theme={tableTheme}>
				<TopPanel />
				<div style={{ paddingTop: 'var(--top-bar-height)', height: '100vh' }}>
					<Routes>
						<Route path='/' element={<Dashboard />} />
						<Route path='*' element={<Navigate to='/' />} />
					</Routes>
				</div>
			</ThemeProvider>
			:
			<div style={{ height: '100vh', width: '100vw' }}>
				<LoadingDisplay />
			</div>
	);
}

export default App;
