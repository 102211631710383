import React, { useState } from 'react'
import { PasswordTextField } from '../../Inputs/TextField/TextField'
import Button from '../../Buttons/Button'
import { Controller, useForm } from 'react-hook-form'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { updatePassword } from '../../../API/Requests'
import { useTranslation } from 'react-i18next'

import './MyProfile.css'

function ChangePassword(props) {
    const { control, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const { showSnackbar } = useGlobalSnackbar()
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()


    const onSubmit = data => {
        setLoading(true)

        updatePassword(props.user.id, data).then(res => {
            reset()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: err.response?.data ?? t('errors.timeout.description'), variant: "error" })
            setLoading(false)
        })
    }

    return (
        <form className='edit-profile-form-fields' onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name='newPassword'
                defaultValue=''
                rules={{
                    required: t('inputs.mandatory'),
                    pattern: { value: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/, message: t('inputs.password.errors.invalid') }
                }}
                render={({ field: { value, onChange } }) => (
                    <PasswordTextField
                        label={t('inputs.password.new')}
                        value={value}
                        onChange={onChange}
                        error={errors.newPassword}
                        helperText={errors.newPassword && errors.newPassword?.message}
                    />
                )}
            />
            <Controller
                control={control}
                name='confirmPassword'
                defaultValue=''
                rules={{
                    required: t('inputs.mandatory'),
                    validate: pass => {
                        if (watch('newPassword') !== pass) {
                            return t('inputs.password.errors.match')
                        }
                    }
                }}
                render={({ field: { value, onChange } }) => (
                    <PasswordTextField
                        label={t('inputs.password.confirm')}
                        value={value}
                        onChange={onChange}
                        error={errors.confirmPassword}
                        helperText={errors.confirmPassword && errors.confirmPassword?.message}
                    />
                )}
            />
            <Controller
                control={control}
                name='authPassword'
                defaultValue=''
                rules={{
                    required: t('inputs.mandatory'),
                }}
                render={({ field: { value, onChange } }) => (
                    <PasswordTextField
                        label={t('inputs.password.current')}
                        style={{ marginTop: 'auto' }}
                        value={value}
                        onChange={onChange}
                        error={errors.authPassword}
                        helperText={t('inputs.password.helper_text.verify')}
                    />
                )}
            />
            <Button style={{ marginTop: '10px' }} loading={loading} type='submit'>{t('common.submit')}</Button>
        </form>
    )
}

export default ChangePassword