import axios from 'axios'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const getHeaders = async () => {
    return {
        "Authorization": JSON.parse(sessionStorage.getItem('token'))?.token,
        "requesting-user": `fo_${localStorage.getItem('id')}`,
        "lang": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'pt'
    }
}

const getFileHeaders = async (data) => {
    return {
        "Authorization": JSON.parse(sessionStorage.getItem('token'))?.token,
        "requesting-user": `fo_${localStorage.getItem('id')}`,
        'accept': 'application/json',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        "lang": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'pt'
    }
}

export const loginUser = async (email, password, rememberMe) => {
    return await axios.post(`${SERVER_URL}/api/app_users/auth`, { email, password, rememberMe }, { headers: { "lang": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'pt' } })
}

export const verifyUser = async (rememberMe, userId) => {
    return await axios.post(`${SERVER_URL}/api/app_users/verify`, { rememberMe, userId }, { headers: { "lang": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'pt' } })
}

export const sendForgotPass = async (email) => {
    return await axios.post(`${SERVER_URL}/api/app_users/forgot-pass`, { email }, { headers: { "lang": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'pt' } })
}

export const resetPass = async (data) => {
    return await axios.post(`${SERVER_URL}/api/app_users/reset-pass`, data, { headers: { "lang": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'pt' } })
}

export const updatePassword = async (id, data) => {
    return await axios.post(`${SERVER_URL}/api/app_users/change-pass/${id}`, data, { headers: await getHeaders() })
}

export const getUserForPassReset = async (id, secret) => {
    return await axios.get(`${SERVER_URL}/api/app_users/get-user-for-pass-reset/${id}/${secret}`, { headers: { "lang": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'pt' } })
}

export const getEntity = async (entity, id) => {
    return await axios.get(`${SERVER_URL}/api/${entity}${id ? `/${id}` : ''}`, { headers: await getHeaders() })
}

export const updateUserProfile = async (id, data) => {
    return await axios.put(`${SERVER_URL}/api/app_users/update-profile/${id}`, data, { headers: await getHeaders() })
}

export const changePicture = async (id, data) => {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/app_users/change-picture/${id}`, data, { headers: await getFileHeaders(data) })
}

export const removePicture = async (id) => {
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/api/app_users/remove-picture/${id}`, {}, { headers: await getHeaders() })
}

export const getByCompany = async (entity, id) => {
    return await axios.get(`${SERVER_URL}/api/${entity}/get-by-company/${id}`, { headers: await getHeaders() })
}

export const checkProject = async (id) => {
    return await axios.get(`${SERVER_URL}/api/projects/check/${id}`, { headers: { 'lang': 'pt' } })
}

export const getProjectsFromUser = async (id) => {
    return await axios.get(`${SERVER_URL}/api/app_users/getProjects/${id}`, { headers: await getHeaders() })
}

export const addShare = async (entity, id, platform) => {
    return await axios.post(`${SERVER_URL}/api/${entity}/add-share/${id}`, { platform }, { headers: await getHeaders() })
}

export const getTablesByCompany = async (id) => {
    return await axios.get(`${SERVER_URL}/api/tables/get-by-company/${id}`, { headers: await getHeaders() })
}

export const contactEmail = async (data) => {
    return await axios.post(`${SERVER_URL}/api/contact`, data, { headers: { "lang": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'pt' } })
}