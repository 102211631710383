import React from 'react'
import { BeatLoader } from 'react-spinners'

import './Button.css'

function Button({ loading, size, ...props }) {

    return (
        <button
            className={`loading-button-main-div${loading ? ' loading' : ''} ${size ? size : 'medium'}`}
            disabled={loading || props.disabled}
            {...props}
        >
            {loading ? (
                <div className='button-loading-div'>
                    <BeatLoader color='white' />
                </div>
            ) : (
                props.children
            )}
        </button>
    )
}

export default Button