import React from 'react'
import { Dialog } from '@mui/material'
import CustomTooltip from '../Misc/CustomTooltip'
import PropTypes from 'prop-types'
import { Close } from '@mui/icons-material'

import './DialogPrefab.css'

function DialogPrefab({ children, open, close, maxWidth, title, preventOutSideClose, error, ...props }) {

    const handleClose = (e, reason) => {
        if (preventOutSideClose) {
            if (reason && reason === 'backdropClick') {
                return;
            }
        }
        close()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            fullScreen={props.fullScreen ? props.fullScreen : false}
            maxWidth={maxWidth}
            className='dialog-outer-div'
            {...props}
        >
            <div className='dialog-prefab-wrapper'>
                <div className='dialog-prefab-title-div'>
                    <p className='dialog-prefab-title'>{title}</p>
                {!props.preventClose &&
                    <CustomTooltip title='Fechar janela'>
                        <Close className='dialog-prefab-close-icon' htmlColor='white' onClick={() => close()} />
                    </CustomTooltip>
                }
                </div>
                {children}
                <div className='dialog-prefab-background-div' />
            </div>
        </Dialog>
    )
}

export default DialogPrefab

DialogPrefab.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', ''])
}