import React from 'react'

import './AutoCompleteInput.css'

function AutoCompleteInput({ params, label }) {
    return (
        <div className='autocomplete-input-div' ref={params.InputProps.ref}>
            <input {...params.inputProps} className='autocomplete-input' placeholder={label}/>
        </div>
    )
}

export default AutoCompleteInput