import React, { useState } from 'react'
import LanguageChanger from '../../Components/Misc/LanguageChanger/LanguageChanger'
import { useTranslation } from 'react-i18next'
import { ClickAwayListener } from '@mui/material'
import useDialog from '../../Hooks/useDialog'

import './Footer.css'

function Footer() {
    const [showMobilePanel, setShowMobilePanel] = useState(false)
    const { t } = useTranslation()
    const { openContacts, openCookies, openPrivacy, openTnc } = useDialog()

    return (
        <div className='footer-main-div'>
            <div className='footer-links'>
                <p onClick={openTnc}>{t('footer.tnc')}</p>
                <p onClick={openCookies}>{t('footer.cookies')}</p>
                <p onClick={openPrivacy}>{t('footer.privacy')}</p>
                <p onClick={openContacts}>{t('footer.contacts')}</p>
            </div>
            <ClickAwayListener onClickAway={() => setShowMobilePanel(false)}>
                <div className='footer-links-mobile'>
                    <p onClick={() => setShowMobilePanel(!showMobilePanel)}>{showMobilePanel ? t('footer.hide') : t('footer.show')}</p>
                    <div className={`footer-mobile-panel ${showMobilePanel && 'show'}`}>
                        <p onClick={openTnc}>{t('footer.tnc')}</p>
                        <p onClick={openCookies}>{t('footer.cookies')}</p>
                        <p onClick={openPrivacy}>{t('footer.privacy')}</p>
                        <p onClick={openContacts}>{t('footer.contacts')}</p>
                    </div>
                </div>
            </ClickAwayListener>
            <LanguageChanger />
        </div>
    )
}

export default Footer