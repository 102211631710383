import React, { useState, useEffect } from "react";
import ProjectsList from "../Components/Dashboard/ProjectsList";
import SharedProjects from "../Components/Dashboard/SharedProjects";
import { getProjectsFromUser } from "../API/Requests";
import { useUserInfo } from "../Hooks/useUser";
import LoadingDisplay from "../Components/Misc/LoadingDisplay";
import { useTranslation } from "react-i18next";

import "./css/Dashboard.css";

function Dashboard() {
	const [panel, setPanel] = useState("projects");
	const [projects, setProjects] = useState([]);
	const [sharedProjects, setSharedProjects] = useState([]);
	const { userInfo } = useUserInfo();
	const [loading, setLoading] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		if (userInfo.id) {
			getProjectsFromUser(userInfo.id).then(
				res => {
					setProjects(res.data.projects);
					setSharedProjects(res.data.user.sharedProjects);
					setLoading(false);
				},
				err => {
					console.error(err);
					setLoading(false);
				},
			);
		}
	}, [userInfo.id]);

	return (
		<div className="dashboard-main-div">
			<div className="dashboard-tabs-div">
				<p
					className={`dashboard-tab ${panel === "projects" ? "active" : "inactive"}`}
					onClick={() => setPanel("projects")}
				>
					{t("dashboard.tab1")}
				</p>
				<p style={{ fontSize: "30px" }}>|</p>
				<p
					className={`dashboard-tab ${panel === "shared" ? "active" : "inactive"}`}
					onClick={() => setPanel("shared")}
				>
					{t("dashboard.tab2")}
				</p>
			</div>
			{loading ? (
				<LoadingDisplay message="Loading projects, please wait" />
			) : (
				<div className="tabs-main-div">
					<div className={`dashboard-tabs-wrapper ${panel === "projects" ? "show" : "hide"}`}>
						<ProjectsList projects={projects} />
					</div>
					<div className={`dashboard-tabs-wrapper ${panel === "shared" ? "show" : "hide"}`}>
						<SharedProjects projects={sharedProjects} />
					</div>
				</div>
			)}
		</div>
	);
}

export default Dashboard;
