import React from 'react'
import { Autocomplete, Checkbox, Popper, TextField, Paper } from '@mui/material'
import { CheckBox, CheckBoxOutlineBlank, Check } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import './CustomAutocomplete.css'
import AutoCompleteInput from './AutoCompleteInput'

const CustomPopper = (props) => {
    return <Popper
        {...props}
        style={{ width: 'max-content', maxWidth:'200px' }}
    />
}

const CustomTransparentPaper = (props) => {
    return <Paper
        sx={{ backgroundColor: 'rgba(255,255,255,0.4)', borderRadius: 0, backdropFilter: 'blur(2px)'}}
        {...props}
    />
}

const CustomAutoCompleteStyled = styled(TextField)({
    margin: '0',
    minWidth: '150px',
    '&:hover': {
        '& MuiAutocomplete-endAdornment': {
            'button': {
                color: 'black'
            }
        },
        '& input': {
            color: 'black',
        },
    },
    '& .MuiFormHelperText-root': {
        color: 'var(--textfield-error) !important',
        fontSize: '12px',
        marginLeft: '7px',
        fontFamily: '"JetBrains Mono", monospace'
    },
    ' label': {
        color: 'white',
        '&.Mui-focused': {
            color: 'white',
            backgroundColor: 'black',
            padding: '0 5px'
        }
    },
    '& .MuiInputBase-root': {
        width: '100%',
        borderRadius: '0',
        letterSpacing: '1px',
        fontWeight: '300',
        border: '1px solid white',
        outline: 'none',
        transition: 'box-shadow 0.2s ease-in-out',
        '&:hover': {
            backgroundColor: 'white',

        },
        '&:focus-visible': {
            boxShadow: 'var(--textfield-shadow-hover)'
        },
        '&.Mui-focused': {
            boxShadow: 'var(--textfield-shadow-hover)'
        },
        '& fieldset': {
            border: 'none',
        },
        '& input': {
            color: 'white',
            fontFamily: '"JetBrains Mono", monospace',
        },
        '> p': {
            fontSize: '14px'
        },
        '&.Mui-error fieldset': {
            border: '1px solid var(--textfield-shadow-border)',
            boxShadow: 'var(--textfield-shadow-error)'
        },
        '& .MuiAutocomplete-endAdornment': {
            'button': {
                color: 'white'
            }
        }
    },
})

const ListboxComponent = React.forwardRef(function ListboxComponent(rest, ref) {
    return (
        <div className='list-box-div'>
            <ul ref={ref} {...rest}></ul>
        </div>
    );
});

export function FilterAutoComplete({ data, label, onChange, value, name, optionLabel, helperText, error, customLabel, tagText, ...props }) {

    return (
        <Autocomplete
            PaperComponent={CustomTransparentPaper}
            id={name}
            multiple={true}
            disableCloseOnSelect={true}
            getOptionLabel={(option) => option}
            tabIndex={-1}
            options={data}
            limitTags={1}
            PopperComponent={CustomPopper}
            openOnFocus
            value={value}
            ListboxComponent={ListboxComponent}
            onChange={(e, newValue) => onChange(newValue)}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option, { selected }) => (
                <li {...props} className={`list-box-list-item${selected ? ' selected' : ''}`}>
                    <p>{option}</p>
                    <div className={`list-box-list-item-icon ${selected ? 'show' : 'hide'}`}>
                        <Check htmlColor='white' sx={{filter: 'drop-shadow(1px 1px 1px black)'}} />
                    </div>
                </li>
            )}
            renderInput={(params) =>
                <AutoCompleteInput params={params} label={label} />
            }
            {...props}
        />
    )
}

export function GenericAutoComplete({ data, label, id, onChange, value, name, optionLabel, helperText, error, customLabel, tagText, ...props }) {

    return (
        <Autocomplete
            id={id}
            renderTags={(tagValue) =>
                <p style={{ marginLeft: '10px' }}>{`${tagValue.length} ${tagText ? `${tagText} selecionado(s) ` : `${label} selecionado(s) `}`}</p>
            }
            tabIndex={-1}
            options={data}
            limitTags={1}
            PopperComponent={CustomPopper}
            openOnFocus
            value={value}
            onChange={(e, newValue) => onChange(newValue)}
            getOptionLabel={optionLabel ? (option) => option[optionLabel] : props.getOptionLabel}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option, { selected }) => (
                <li {...props} style={{ width: '250px', height: 'fit-content', fontSize: '16px', backgroundColor: 'black' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize='small' />}
                        checkedIcon={<CheckBox fontSize='small' />}
                        style={{ color: 'var(--text)' }}
                        checked={selected}
                    />
                    {customLabel ? option[customLabel] : option[optionLabel]}
                </li>
            )}
            renderInput={(params) =>
                <div style={{ marginBottom: '20px' }}>
                    <p style={{ color: 'var(--text)' }}>{label}</p>
                    <CustomAutoCompleteStyled tabIndex={1} {...params} error={error} name={name} helperText={helperText} />
                </div>
            }
            {...props}
        />
    )
}