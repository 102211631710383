import React, { useState } from "react";
import DialogPrefab from "../DialogPrefab";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Button from "../../Buttons/Button";
import useGlobalSnackbar from "../../../Hooks/useGlobalSnackbar";
import { contactEmail } from "../../../API/Requests";
import { TextField } from "../../Inputs/TextField/TextField";
import TextArea from "../../Inputs/TextField/TextArea";
import moment from "moment";

import "./Contacts.css";

function Contacts(props) {
	const { t } = useTranslation();
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();
	const { showSnackbar } = useGlobalSnackbar();
	const [loading, setLoading] = useState(false);

	const onSubmit = data => {
		setLoading(true);
		contactEmail(data).then(
			res => {
				reset({ name: "", email: "", message: "" });
				showSnackbar({ message: res.data });
				setLoading(false);
			},
			err => {
				console.log(err);
				showSnackbar({ message: err.response?.data ?? t("errors.timeout.description"), variant: "error" });
				setLoading(false);
			},
		);
	};

	return (
		<DialogPrefab
			open={props.open}
			close={props.close}
			title={t("footer.contacts")}
			maxWidth="md"
			fullScreen={window.innerWidth < 960}
		>
			<div className="contacts-dialog-main-div">
				<form className="contact-dialog-form-div" onSubmit={handleSubmit(onSubmit)}>
					<div className="contacts-dialog-joined-fields">
						<Controller
							control={control}
							name="name"
							defaultValue=""
							rules={{
								required: t("inputs.mandatory"),
							}}
							render={({ field: { value, onChange } }) => (
								<TextField
									label={t("inputs.name.title")}
									name="name"
									onChange={onChange}
									value={value}
									error={errors.name}
									helperText={errors.name?.message}
								/>
							)}
						/>
						<Controller
							control={control}
							name="email"
							defaultValue=""
							rules={{
								required: t("inputs.mandatory"),
								pattern: {
									value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: t("inputs.email.errors.invalid"),
								},
							}}
							render={({ field: { value, onChange } }) => (
								<TextField
									label={t("inputs.email.title")}
									name="email"
									onChange={onChange}
									value={value}
									error={errors.email}
									helperText={errors.email?.message}
								/>
							)}
						/>
					</div>
					<Controller
						control={control}
						name="message"
						defaultValue=""
						rules={{
							required: t("inputs.mandatory"),
							minLength: { value: 15, message: t("inputs.message.errors.invalid") },
						}}
						render={({ field: { value, onChange } }) => (
							<TextArea
								label={t("inputs.message.title")}
								name="message"
								rows="5"
								onChange={onChange}
								value={value}
								error={errors.message}
								helperText={errors.message?.message}
							/>
						)}
					/>
					<Button style={{ marginTop: "20px" }} loading={loading} type="submit">
						{t("contacts.send")}
					</Button>
				</form>
				<div className="contact-dialog-right-div">
					<p style={{ fontSize: "22px", marginBottom: "20px" }}>{t("contacts.title")}</p>
					<p style={{ textAlign: "center", marginBottom: "20px" }}>{t("contacts.subtitle")}</p>
					<p style={{ fontWeight: "700" }}>{t("contacts.phone", { gmt: moment().utcOffset() / 60 })}:</p>
					<p>938 929 521</p>
					<p style={{ marginTop: "20px", fontWeight: "700" }}>Email:</p>
					<p>support@faunalab.com</p>
				</div>
			</div>
		</DialogPrefab>
	);
}

export default Contacts;
