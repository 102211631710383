import React, { useState, useEffect, useRef } from 'react'
import ProjectCard from '../Cards/ProjectCard'

import arrow from '../../Assets/Icons/arrow.svg'

import './ProjectsContainer.css'

function ProjectsContainer(props) {
    const [expanded, setExpanded] = useState(false)
    const [showExpand, setShowExpand] = useState(true)
    const containerRef = useRef(null)
    const [containerHeight, setContainerHeight] = useState(200)

    const observer = new IntersectionObserver(entries => {
        if (containerRef.current) {
            setShowExpand(!entries[0].isIntersecting)
        }
    }, { threshold: 0, rootMargin: '0px 0px', root: containerRef.current })

    useEffect(() => {
        const container = containerRef.current
        const containerLastChild = container?.children[container.children.length - 1]

        if (container) observer.observe(containerLastChild);
        return () => {
            if (container) observer.unobserve(containerLastChild)
        }
    }, [containerRef, props])


    const handleExpand = () => {
        if (expanded) {
            setContainerHeight(200)
        } else {
            setContainerHeight(containerRef.current.scrollHeight)
        }

        setExpanded(!expanded)
    }

    return (
        <div className='projects-container-main-div'>
            <div className={`projects-container-div${expanded ? ' expanded' : ''}`} style={{ height: containerHeight }} ref={containerRef}>
                {props.projects?.map(proj => (
                    <ProjectCard key={proj.id} project={proj} />
                ))}
            </div>
            <div className='projects-container-action'>
                <img
                    className={`expand-arrow ${expanded ? 'expanded' : 'retracted'} ${showExpand ? 'show' : 'hide'}`}
                    alt='expand'
                    src={arrow}
                    onClick={handleExpand}
                />
            </div>

        </div>
    )
}

export default ProjectsContainer