import React from 'react'
import DialogPrefab from '../DialogPrefab'
import { useTranslation } from 'react-i18next'
import './FooterDialogs.css'

function Privacy(props) {
    const { t, i18n } = useTranslation()

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            title={t('footer.privacy')}
            maxWidth='lg'
            fullScreen={window.innerWidth < 960}
        >
            {i18n.language === 'pt' ?
                <div className='text-main-div'>
                    <p>Na Faunalab VR, acessível a partir de https://faunalabvr.com, uma das nossas principais prioridades é a privacidade dos nossos visitantes. Este documento de Política de Privacidade contém tipos de informação que é recolhida e registada pela Faunalab VR e a forma como a utilizamos.</p>

                    <p>Se tiver perguntas adicionais ou necessitar de mais informações sobre a nossa Política de Privacidade, não hesite em contactar-nos.</p>

                    <h1>Ficheiros de registo</h1>

                    <p>Faunalab VR segue um procedimento padrão de utilização de ficheiros de registo. Estes ficheiros registam os visitantes quando estes visitam páginas web. Todas as empresas de alojamento fazem isto e uma parte da análise dos serviços de alojamento. As informações recolhidas pelos ficheiros de registo incluem endereços de Protocolo de Internet (IP), tipo de navegador, Fornecedor de Serviços de Internet (ISP), carimbo de data e hora, páginas de referência/saída e, possivelmente, o número de cliques. Estes não estão ligados a qualquer informação que seja pessoalmente identificável. O objetivo da informação é analisar tendências, administrar a página web, acompanhar o movimento dos utilizadores na página web e recolher informação demográfica. </p>

                    <h1>Cookies e Web Beacons</h1>

                    <p>Como qualquer outra página web, o Faunalab VR utiliza 'cookies'. Estes cookies são utilizados para armazenar informação, incluindo as preferências dos visitantes, e as páginas web que o visitante acedeu ou visitou. A informação é utilizada para otimizar a experiência dos utilizadores, personalizando o conteúdo da nossa página web com base no tipo de browser dos visitantes e/ou outras informações. Pode optar por desativar os cookies através das opções individuais do seu navegador. Para saber informações mais detalhadas sobre a gestão de cookies em navegadores web específicos, pode encontrá-las nas respetivas páginas web dos navegadores.</p>

                    <p>Pode optar por desativar os cookies através das opções individuais do seu navegador. Para saber informações mais detalhadas sobre a gestão de cookies com navegadores web específicos, pode encontrá-las nos respetivos sítios web dos navegadores.</p>

                    <h1>Informação para crianças</h1>

                    <p>Outra parte da nossa prioridade é acrescentar proteção para as crianças enquanto utilizam a Internet. Encorajamos os pais e tutores a observar, participar, monitorizar e orientar a sua atividade online.</p>

                    <p>Faunalab VR não recolhe intencionalmente qualquer Informação Pessoal identificável de crianças menores de 13 anos. Se pensa que o seu filho forneceu este tipo de informação ao nossa página web, encorajamo-lo vivamente a contactar-nos imediatamente e faremos os nossos melhores esforços para remover prontamente tal informação dos nossos registos.</p>

                    <h1>Política de Privacidade Online Apenas</h1>

                    <p>Esta Política de Privacidade aplica-se apenas às nossas atividades online e é válida para os visitantes da nossa página web no que respeita às informações que partilharam e/ou recolheram em Faunalab VR. Esta política não é aplicável a qualquer informação recolhida offline ou através de outros canais que não esta página web.</p>

                    <h1>Consentimento</h1>

                    <p>Ao utilizar o nosso website, o utilizador consente com a nossa Política de Privacidade</p>
                </div>
                :
                <div className='text-main-div'>
                    <p>At Faunalab VR, accessible from https://faunalabvr.com, one of our top priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Faunalab VR and how we use it.</p>

                    <p>If you have additional questions or require more information about our Privacy Policy, please do not hesitate to contact us.</p>

                    <h1>Log Files</h1>

                    <p>Faunalab VR follows a standard procedure for using log files. These files record visitors when they visit web pages. All hosting companies do this and it's a part of reviewing hosting services. Information collected by log files includes Internet Protocol (IP) addresses, browser type, Internet Service Provider (ISP), timestamps, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is to analyze trends, administer the website, track user movements on the website and collect demographic information.</p>

                    <h1>Cookies and Web Beacons</h1>

                    <p>Like any other website, Faunalab VR uses 'cookies'. These cookies are used to store information, including visitor preferences, and the web pages the visitor accessed or visited. The information is used to optimize the user experience, customizing the content of our website based on the type of browser of the visitors and/or other information. You can choose to disable cookies through your individual browser options. To find out more detailed information about the management of cookies in specific web browsers, you can find them on the browsers' respective web pages.</p>

                    <p>You can choose to disable cookies through your individual browser options. For more detailed information on the management of cookies with specific web browsers, you can find them on the browsers' respective websites.</p>

                    <h1>Information for children</h1>

                    <p>Another part of our priority is adding protection for children while using the Internet. We encourage parents and guardians to observe, participate, monitor and guide your online activity.</p>

                    <p>Faunalab VR does not knowingly collect any Personally Identifiable Information from children under the age of 13. If you believe that your child has provided this type of information to our website, we strongly encourage you to contact us immediately and we will use our best efforts to promptly remove such information from our records.</p>

                    <h1>Online Privacy Policy Only</h1>

                    <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regard to the information they shared and/or collected in Faunalab VR. This policy does not apply to any information collected offline or through channels other than this website.</p>

                    <h1>Concent</h1>

                    <p>By using our website, the user consents to our Privacy Policy</p>
                </div>
            }
        </DialogPrefab>
    )
}

export default Privacy