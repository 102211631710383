import React, { useRef } from 'react'
import DialogPrefab from '../DialogPrefab'
import moment from 'moment'
import { Button, Divider, Tooltip } from '@mui/material'
import { Download, Save, Link } from '@mui/icons-material'
import TableImage from '../../../Assets/placeholder/table.png'
import domtoimage from 'dom-to-image'
import { ExportToCsv } from 'export-to-csv'
import { useTranslation } from 'react-i18next'
import sortArray from 'sort-array'

import './TableDialog.css'
import { openInNewTab } from '../../../utils'

function TableDialog(props) {
    const tableRef = useRef(null)
    const { t } = useTranslation()

    const options = {
        showLabels: true,
        filename: `${props.table.name}_data`,
        useBom: true,
        headers: ['Brand', 'Ordered By', 'Order Date', 'Project']
    };

    const csvExporter = new ExportToCsv(options);

    const exportTableImage = () => {
        if (tableRef.current) {
            domtoimage.toJpeg(tableRef.current).then((dataUrl) => {
                var link = document.createElement('a');
                link.download = `${props.table.name}_table.jpeg`
                link.href = dataUrl;
                link.click();
            })
        }
    }

    const exportData = () => {
        const data = []

        props.table.tables_rows.forEach(rows => {
            rows.table_projects.forEach(project => {
                data.push(project)
            })
        })

        const mappedData = data.map(el => (
            { brand: el.brand, orderBy: el.orderBy, orderDate: moment(el.orderDate).format('DD MMMM YYYY'), project: el.project?.name ?? 'N/D' }
        ))

        csvExporter.generateCsv(mappedData);
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='lg'
            fullScreen={window.innerWidth < 960}
            title={props.table.name}
        >
            <div className='table-info-main-div'>
                <div className='table-info-top-div'>
                    <img alt='table' src={TableImage} />
                    <div className='table-info-div'>
                        <p>{t('tables.name')}: <span>{props.table.name}</span></p>
                        <p>{t('tables.type')}: <span style={{ textTransform: 'capitalize' }}>{props.table.type.replace('_', ' ')}</span></p>
                        <p>{t('tables.created')}: <span>{moment(props.table.createdAt).format('DD MMM YYYY HH:mm')}</span></p>
                        <p>{t('tables.modified')}: <span>{moment(props.table.updatedAt).format('DD MMM YYYY HH:mm')}</span></p>
                    </div>
                    <div className='table-actions-div'>
                        <Button
                            aria-label='export table'
                            variant='contained'
                            color='primary'
                            size='small'
                            startIcon={<Download htmlColor='white' />}
                            onClick={() => exportData()}
                        >
                            {t('tables.export_button')}
                        </Button>
                        <Button
                            aria-label='print table'
                            variant='contained'
                            color='primary'
                            size='small'
                            startIcon={<Save htmlColor='white' />}
                            onClick={() => exportTableImage()}
                        >
                            {t('tables.save_button')}
                        </Button>
                    </div>
                </div>
                <div className='table-scrollable-wrapper'>
                    <div className='table-info-content' ref={tableRef}>
                        <div className='table-info-header'>
                            <p>#</p>
                            <div className='table-info-inner-header'>
                                <p>Brand</p>
                                <p>Ordered By:</p>
                                <p>Order Date:</p>
                                <p>Project</p>
                            </div>
                        </div>
                        {sortArray(props.table.tables_rows, { by: 'id', order: 'asc' }).map((rows, index) => (
                            <React.Fragment key={rows.id}>
                                <div className='table-info-rows'>
                                    <p>{index + 1}</p>
                                    <div className='table-info-inner-rows'>
                                        {sortArray(rows.table_projects, { by: 'createdAt', order: 'asc' }).map(row => (
                                            <div key={row.id} className='table-info-projects'>
                                                <p>{row.brand}</p>
                                                <p>{row.orderBy}</p>
                                                <p>{moment(row.orderDate).format('DD MMMM YYYY')}</p>
                                                {row.project ?
                                                    <div className='table-project-div'>
                                                        <Tooltip title='Visit'>
                                                            <Link onClick={() => openInNewTab(`${process.env.REACT_APP_THIS_URL}/projects?name=${row.project.folder}`)}/>
                                                        </Tooltip>
                                                        <p>{row.project.name}</p>
                                                    </div>
                                                    :
                                                    <p>teste</p>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <Divider orientation='horizontal' sx={{ width: '100%', backgroundColor: 'white' }} />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </DialogPrefab>
    )
}

export default TableDialog