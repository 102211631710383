import React from 'react'
import DialogPrefab from '../DialogPrefab'
import { useTranslation } from 'react-i18next'
import './FooterDialogs.css'

function Cookies(props) {
    const { t, i18n } = useTranslation()

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            title={t('footer.cookies')}
            maxWidth='md'
            fullScreen={window.innerWidth < 960}
        >
            {i18n.language === 'pt' ?
                <div className='text-main-div'>

                    <h2>Cookies</h2>

                    <p>Como qualquer outra página web, o Faunalab VR utiliza 'cookies'. Estes cookies são utilizados para armazenar informação, incluindo as preferências dos visitantes, e as páginas web que o visitante acedeu ou visitou. A informação é utilizada para otimizar a experiência dos utilizadores, personalizando o conteúdo da nossa página web com base no tipo de browser dos visitantes e/ou outras informações. Pode optar por desativar os cookies através das opções individuais do seu navegador. Para saber informações mais detalhadas sobre a gestão de cookies em navegadores web específicos, pode encontrá-las nas respetivas páginas web dos navegadores.</p>
                    <br />
                    <p>Pode optar por desativar os cookies através das opções individuais do seu navegador. Para saber informações mais detalhadas sobre a gestão de cookies com navegadores web específicos, pode encontrá-las nos respetivos sítios web dos navegadores.</p>

                    <h1>Consentimento</h1>

                    <p>Ao utilizar o nosso website, o utilizador consente com a nossa Política de Cookies</p>
                </div>
                :
                <div className='text-main-div'>

                    <h2>Cookies</h2>

                    <p>Like any other website, Faunalab VR uses 'cookies'. These cookies are used to store information, including visitor preferences, and the web pages the visitor accessed or visited. The information is used to optimize the user experience, customizing the content of our website based on the type of browser of the visitors and/or other information. You can choose to disable cookies through your individual browser options. To find out more detailed information about the management of cookies in specific web browsers, you can find them on the browsers' respective web pages.</p>
                    <br />
                    <p>You can choose to disable cookies through your individual browser options. For more detailed information on the management of cookies with specific web browsers, you can find them on the browsers' respective websites.</p>

                    <h1>Concent</h1>

                    <p>By using our website, the user consents to our Cookies Policy</p>
                </div>
            }
        </DialogPrefab>
    )
}

export default Cookies