import React, { useState } from 'react'
import { TextField, PasswordTextField } from '../../Inputs/TextField/TextField'
import Button from '../../Buttons/Button'
import { Controller, useForm } from 'react-hook-form'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { updateUserProfile } from '../../../API/Requests'
import { useTranslation } from 'react-i18next'

import './MyProfile.css'

function EditProfile(props) {
    const { control, handleSubmit, reset, formState: { errors } } = useForm();
    const { showSnackbar } = useGlobalSnackbar()
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()

    const onSubmit = data => {
        const changedData = {}

        for (const key in data) {
            if (data[key] !== props.user[key]) {
                changedData[key] = data[key]
            }
        }

        if (Object.keys(changedData).length === 1) {
            showSnackbar({ message: t('profile.edit_info.no_change'), variant: 'info' })
            setLoading(false)
            return;
        }

        setLoading(true)
        updateUserProfile(props.user.id, changedData).then(res => {
            props.refresh()
            reset({ authPassword: '' });
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: err.response?.data ?? t('errors.timeout.description'), variant: "error" })
            setLoading(false)
        })
    }

    return (
        <form className='edit-profile-form-fields' onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name='name'
                defaultValue={props.user.name}
                rules={{
                    required: t('inputs.mandatory')
                }}
                render={({ field: { value, onChange } }) => (
                    <TextField
                        label={t('inputs.name.title')}
                        name='name'
                        onChange={onChange}
                        value={value}
                        error={errors.name}
                        helperText={errors.name && errors.name?.message}
                    />
                )}
            />
            <Controller
                control={control}
                name='email'
                defaultValue={props.user.email}
                rules={{
                    required: t('inputs.mandatory'),
                    pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: t('inputs.email.errors.invalid')}
                }}
                render={({ field: { value, onChange } }) => (
                    <TextField
                    label={t('inputs.email.title')}
                        name='email'
                        onChange={onChange}
                        value={value}
                        error={errors.email}
                        helperText={errors.email && errors.email?.message}
                    />
                )}
            />
            <Controller
                control={control}
                name='authPassword'
                defaultValue=''
                rules={{
                    required: t('inputs.mandatory'),
                }}
                render={({ field: { value, onChange } }) => (
                    <PasswordTextField
                        style={{ marginTop: 'auto' }}
                        value={value}
                        onChange={onChange}
                        error={errors.authPassword}
                        helperText={t('inputs.password.helper_text.verify')}
                    />
                )}
            />
            <Button style={{ marginTop: '20px' }} loading={loading} type='submit'>{t('common.submit')}</Button>
        </form>
    )
}

export default EditProfile