import React, { useEffect, useState } from 'react'
import DialogPrefab from '../DialogPrefab'
import { getTablesByCompany } from '../../../API/Requests'
import TableImage from '../../../Assets/placeholder/table.png'
import moment from 'moment'
import { Chip } from '@mui/material'
import useDialog from '../../../Hooks/useDialog'
import { DotLoader } from 'react-spinners'
import { useTranslation } from 'react-i18next'

import './ProjectsTablesMain.css'

function ProjectsTablesMain(props) {
    const [tables, setTables] = useState([])
    const { openTableInfoDialog } = useDialog()
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        setLoading(true)
        getTablesByCompany(props.company.id).then(res => {
            setTables(res.data)
            setLoading(false)
        }, err => {
            setLoading(false)
            console.log(err)
        })
    }, [])

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='md'
            fullScreen={window.innerWidth < 960}
            title={`${props.company.name} Tables`}
        >
            <div className='tables-dialog-container'>
                {loading ?
                    <div className='projects-table-loading'>
                        <DotLoader color='white' />
                        <p>{t('common.loading_tables')}</p>
                    </div>
                    :
                    tables.length > 0 ?
                        tables.map(table => (
                            <div key={table.id} className='table-container' onClick={() => openTableInfoDialog(table)}>
                                <div className='year-chip'><Chip label={table.year} size='small' color='primary' /></div>
                                <img alt={table.name} src={TableImage} />
                                <p className='table-content-title'>{table.name}</p>
                                <Chip sx={{ textTransform: 'capitalize' }} size='small' color='primary' label={table.type.replace('_', ' ')} />
                                <p>{t('tables.created')}:  {moment(table.createdAt).format('DD MMM YYYY HH:mm')}</p>
                                <p>{t('tables.modified')}: {moment(table.updatedAt).format('DD MMM YYYY HH:mm')}</p>
                            </div>
                        ))
                        :
                        <p style={{ color: 'white', height: '200px', lineHeight: '200px' }}>No tables to display</p>
                }
            </div>
        </DialogPrefab>
    )
}

export default ProjectsTablesMain