import React from 'react'

import './TextArea.css'

function TextArea({ style, helperText, helperTextAction, error, dark, ...props }) {
    return (
        <div className={`textarea-main-div`} style={style}>
            <p className='textarea-label'>{props.label}</p>
            <textarea
                 className={`textarea-input-input${helperText ? ' helper-text' : ''}${error ? ' error' : ''}`}
                rows={props.rows}
                type='text'
                value={props.value}
                placeholder={props.placeholder}
                onChange={props.onChange}
                {...props}
            />
            {helperTextAction ?
                <p className='helper-text-action' onClick={helperTextAction}>{helperText}</p> : <p className='helper-text-p'>{helperText}</p>
            }
        </div>
    )
}

export default TextArea