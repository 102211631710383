import React, { useState } from 'react'
import CustomTooltip from '../Misc/CustomTooltip'
import { openInNewTab, skinString } from '../../utils'
import CustomMenu from '../Misc/CustomMenu/CustomMenu'
import { MenuItem, ListItemIcon } from '@mui/material'
import { PictureAsPdf } from '@mui/icons-material'
import ShareDialog from '../Dialogs/Sharedialog/ShareDialog'
import { useTranslation } from 'react-i18next'
import QRCodeDialog from '../Dialogs/QRCode/QRCodeDialog'

import pdfDownloadIcon from '../../Assets/Icons/download.svg'
import qrCodeIcon from '../../Assets/Icons/qrcode.svg'
import shareIcon from '../../Assets/Icons/share.svg'

import './ProjectCard.css'

function ProjectCard({ project }) {
    const [openQRCodeDialog, setOpenQRCodeDialog] = useState(false)
    const [openShare, setOpenShare] = useState(false)
    const { t } = useTranslation()

    const openPdf = (file) => {
        const pdf = `${process.env.REACT_APP_SERVER_URL}/project/${project.folder}/files/${file}`
        openInNewTab(pdf)
    }

    return (
        <div className='project-card-main-div'>
            <div className='project-card-name-div'>
                <p>{project.name}</p>
            </div>
            <img alt={project.name} src={`${process.env.REACT_APP_SERVER_URL}/project/${project.folder}/socialThumbnail.jpg`} onClick={() => openInNewTab(`${process.env.REACT_APP_THIS_URL}/projects?name=${project.folder}`)} />
            <div className='project-card-actions-div'>
                {project.pdfFiles &&
                    <PDFMenu pdfFiles={project.pdfFiles} openPdf={openPdf} />
                }
                <CustomTooltip title='QR Code'>
                    <img className='project-icon' alt='qr code' src={qrCodeIcon} onClick={() => setOpenQRCodeDialog(true)} />
                </CustomTooltip>
                <CustomTooltip title={t('tooltips.share_project')}>
                    <img className='project-icon' alt='share project' src={shareIcon} onClick={() => setOpenShare(true)} />
                </CustomTooltip>
            </div>
            <QRCodeDialog open={openQRCodeDialog} close={() => setOpenQRCodeDialog(false)} url={`${process.env.REACT_APP_THIS_URL}/projects?name=${project.folder}`} />
            <ShareDialog
                open={openShare}
                close={() => setOpenShare(false)}
                url={`${process.env.REACT_APP_THIS_URL}/projects?name=${project.folder}`}
                quote={project.title}
                hashtag='#360VR'
                description={project.name}
                entity='projects'
                entityId={project.id}
            />
        </div>
    )
}

export default ProjectCard

const PDFMenu = ({ pdfFiles, openPdf }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const { t } = useTranslation()

    const pdfArray = pdfFiles.split('/')

    return (
        pdfArray.length === 1 ?
            <CustomTooltip title={pdfArray[0]}>
                <img alt='download pdf' className='project-icon' src={pdfDownloadIcon} onClick={() => openPdf(pdfArray[0])} />
            </CustomTooltip>
            :
            <>
                <CustomTooltip title={t('tooltips.view_pdfs')}>
                    <img alt='download pdf' className='project-icon' src={pdfDownloadIcon} onClick={(e) => setAnchorEl(e.currentTarget)} />
                </CustomTooltip>
                <CustomMenu
                    id='pdf-menu'
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {pdfArray.map(pdf => (
                        <MenuItem key={pdf} onClick={() => { setAnchorEl(null); openPdf(pdf) }}>
                            <ListItemIcon >
                                <PictureAsPdf htmlColor='white' />
                            </ListItemIcon>
                            {pdf}
                        </MenuItem>
                    ))}
                </CustomMenu>
            </>

    )
}