import React from 'react'
import { Menu} from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledMenu = styled(Menu)(() => ({
    '& .MuiPaper-root': {
        transform: 'translate(5px,5px) !important',
        backgroundColor: '#656aa9',
        color: 'white',
        minWidth: '150px',
        borderRadius: 0,
        boxShadow: 'none',
        '& > ul': {
            paddingTop: 0,
            paddingBottom: 0,
            '& > li': {
                margin: '2.5px 0'
            }
        }
    }
}))

function CustomMenu({ children, ...props }) {
    return (
        <StyledMenu {...props}>
            {children}
        </StyledMenu>
    )
}

export default CustomMenu