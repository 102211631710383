const english = {
	common: {
		password: "Password",
		enter: "Enter",
		my_profile: "My Profile",
		leave: "Leave",
		submit: "Submit",
		cancel: "Cancel",
		view: "View",
		loading_tables: "Loading tables",
	},
	pass_reset: {
		redirect: "You will be redirected to login within 5 seconds",
		title: "Define you new password here",
	},
	errors: {
		timeout: {
			message: "There was an error on your request",
			description: "Cannot communicate with the server, please try again later",
		},
	},
	login: {
		forgotPass: "Did you forgot your password? Clique here",
		remember_me: {
			label: "Remember me on this device",
			tooltip:
				"By selecting this option, on your next visit on this device, you wont need to provide your credentials again (E-mail and Password)",
		},
	},
	inputs: {
		mandatory: "Mandatory Field",
		name: {
			title: "Name",
			errors: {
				invalid: "Invalid name",
			},
		},
		email: {
			title: "Email",
			errors: {
				invalid: "Invalid Email",
			},
		},
		message: {
			title: "Message",
			errors: {
				invalid: "Message too short",
			},
		},
		password: {
			title: "Password",
			confirm: "Confirm your password",
			new: "New password",
			current: "Current Password",
			errors: {
				match: "Password do not match",
				invalid: "Password must contain at least 8 characters with Uppercase, lowercase and numbers",
			},
			helper_text: {
				confirm: "Confirm your new password",
				verify: "Input your current password to verify your identity",
			},
		},
	},
	profile: {
		last_login: "Last Login",
		acc_created: "Account Created at",
		edit_info: {
			title: "Edit info",
			no_change: "No changes detected",
		},
		change_pass: {
			title: "Change password",
		},
		image: {
			upload: "Click or drag to change image",
			remove: "Remove image",
			too_large: "Image too large, limit is 1mb",
			wrong_type: "Wrong file format",
		},
	},
	qrcode_dialog: {
		title: "QR Code",
		buttons: {
			1: "URL",
			2: "Copy QR code",
		},
		response: {
			ok: "QR code copied to clipboard",
			no: "It was not possible to copy the QR code",
			error: "Your browser does not have this functionality",
		},
	},
	share_dialog: {
		response: {
			ok: "URL copied to clipboard",
			error: "It was not possible to copy the URl to clipboard",
		},
	},
	tooltips: {
		share_project: "Share Project",
		copy_url: "Copy URL",
		view_pdfs: "View PDF's",
		copy_qr: "Copy QR code image to clipboard",
		open_url: "Open URL in new tab",
	},
	footer: {
		tnc: "Terms and Conditions",
		cookies: "Cookies Policy",
		privacy: "Privacy Policy",
		contacts: "Contacts",
		show: "Show Policies",
		hide: "Hide Policies",
	},
	dashboard: {
		tab1: "Projects By Company",
		tab2: "projects Shared with me",
		filters: {
			filter1: "Year",
			filter2: "Company",
			search: "Search for project name",
		},
		tables: "View Tables",
		no_projects_shared: "There are no projects shared with you",
	},
	forgot_pass_dialog: {
		title: "Recover Password",
		text: "Input your email registered to be able to recover your password",
		email_not_valid: "is not a valid email",
	},
	tables: {
		created: "Created At",
		modified: "Modified At",
		name: "Table name",
		type: "Table type",
		export_button: "Export Data",
		save_button: "Save table",
	},
	project: {
		not_found: "Project not found",
	},
	filters: {
		show: "Show Filters",
		hide: "Hide Filters",
	},
	contacts: {
		title: "Need help?",
		subtitle: "Use this form to send us a message or use the information below to get in touch with us",
		phone: "Phone Number (09h-18h GMT+{{gmt}})",
		send: "Send Message",
	},
};

export default english;
