import React from 'react'
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
} from "react-share";
import {
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
} from "react-share";
import { Tooltip } from '@mui/material'
import DialogPrefab from '../DialogPrefab';
import { ContentCopy } from '@mui/icons-material'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar';
import { addShare } from '../../../API/Requests';
import { useTranslation } from 'react-i18next';

import './ShareDialog.css'

const ShareDialog = (props) => {
    const { showSnackbar } = useGlobalSnackbar()
    const { t } = useTranslation()

    function onClickCallback(platform) {
        addShare(props.entity, props.entityId, platform)
        props.close()
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='xs'
            title='Share Project'
        >
            <div className='main-share-dialog-div'>
                <Tooltip disableInteractive title='Facebook' placement='top'>
                    <FacebookShareButton
                        style={{ font: 'revert' }}
                        url={props.url}
                        quote={props.quote}
                        hashtag={props.hashtag}
                        description={props.description}
                        beforeOnClick={() => onClickCallback('Facebook')}
                    >
                        <FacebookIcon size={38} round />
                    </FacebookShareButton>
                </Tooltip>
                <Tooltip disableInteractive title='Twitter' placement='top'>
                    <TwitterShareButton
                        style={{ font: 'revert' }}
                        title={props.quote}
                        url={props.url}
                        hashtags={[props.hashtag]}
                        beforeOnClick={() => onClickCallback('Twitter')}
                    >
                        <TwitterIcon size={38} round />
                    </TwitterShareButton>
                </Tooltip>
                <Tooltip disableInteractive title='LinkedIn' placement='top'>
                    <LinkedinShareButton
                        style={{ font: 'revert' }}
                        title={props.quote}
                        url={props.url}
                        summary={props.description}
                        beforeOnClick={() => onClickCallback('LinkedIn')}
                    >
                        <LinkedinIcon size={38} round />
                    </LinkedinShareButton>
                </Tooltip>
                <Tooltip disableInteractive title='Telegram' placement='top'>
                    <TelegramShareButton
                        style={{ font: 'revert' }}
                        title={props.quote}
                        url={props.url}
                        beforeOnClick={() => onClickCallback('Telegram')}
                    >
                        <TelegramIcon size={38} round />
                    </TelegramShareButton>
                </Tooltip>
                <Tooltip disableInteractive title='WhatsApp' placement='top'>
                    <WhatsappShareButton
                        style={{ font: 'revert' }}
                        url={props.url}
                        separator='/'
                        beforeOnClick={() => onClickCallback('WhatsApp')}
                    >
                        <WhatsappIcon size={38} round />
                    </WhatsappShareButton>
                </Tooltip>
                <Tooltip disableInteractive title='Reddit' placement='top'>
                    <RedditShareButton
                        style={{ font: 'revert' }}
                        title={props.quote}
                        url={props.url}
                        beforeOnClick={() => onClickCallback('Reddit')}
                    >
                        <RedditIcon size={38} round />
                    </RedditShareButton>
                </Tooltip>
                <Tooltip disableInteractive title={t('tooltips.copy_url')} placement='top'>
                    <div
                        className='dialog-share-copy-button'
                        onClick={() => copyToClipboard(props.url, props.close, showSnackbar, t)}
                    >
                        <ContentCopy htmlColor='white' />
                    </div>
                </Tooltip>
            </div>
        </DialogPrefab>
    )
}

const copyToClipboard = (url, close, showSnackbar, t) => {
    navigator.clipboard.writeText(url).then(() => {
        showSnackbar({ message: t('share_dialog.response.ok'), variant: 'default', persist: false, autoHideDuration: 2000 })
        close()
    }, err => {
        showSnackbar({ message: t('share_dialog.response.error'), variant: 'error', persist: false, autoHideDuration: 2000 })
        console.log('Error copying to clipboard ', err)
        close()
    })
}

export default ShareDialog
