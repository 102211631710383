import React, { useState, useEffect } from 'react'
import { FilterAutoComplete } from '../Inputs/AutoComplete/CustomAutocomplete'
import SearchInput from '../Inputs/SearchInput/SearchInput'
import moment from 'moment'
import { useDebounce } from '../../utils'
import { Button, Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FilterList } from '@mui/icons-material'

import './Filters.css'

function Filters({ data, setData, view, show, setShow }) {
    const [year, setYear] = useState([])
    const [company, setCompany] = useState([])
    const [filterData, setFilterData] = useState({ year: [], company: [] })
    const [searchString, setSearchString] = useState('')
    const debounceSearch = useDebounce(searchString, 500)
    const { t } = useTranslation()

    useEffect(() => {
        setFilterData(data)

        const yearFilterData = new Set()
        const companyFilterData = new Set()

        data.forEach(el => {
            yearFilterData.add(moment(el.createdAt).format('YYYY'))
            companyFilterData.add(el.company.name)
        })

        setFilterData({ year: [...yearFilterData], company: [...companyFilterData] })
    }, [data])

    useEffect(() => {
        if (searchString.length === 0 && company.length === 0 && year.length === 0) {
            setData(data);
            return;
        };

        const filter = data.filter(proj => (
            (searchString.length > 0 ? proj.name.toLowerCase().includes(searchString.toLowerCase()) : true) &&
            (company.length > 0 ? company.some(el => proj.company.name.toLowerCase() === el.toLowerCase()) : true) &&
            (year.length > 0 ? year.some(el => moment(proj.createdAt).format('YYYY') === el.toLowerCase()) : true)
        ))
        setData(filter)

    }, [debounceSearch, year, company])

    const handleClearFilter = (chipToDelete, type) => {
        if (type === 'year') {
            setYear((data) => data.filter((el) => el !== chipToDelete));
        } else {
            setCompany((data) => data.filter((el) => el !== chipToDelete));
        }
    };

    return (
        <div className={`filters-main-div${show ? ' show': ''}`}>
            <div className='filters-inputs-div'>
                <FilterAutoComplete
                    id={`year-${view}`}
                    label={t('dashboard.filters.filter1')}
                    name='year'
                    data={filterData.year}
                    value={year}
                    onChange={(val) => setYear(val)}
                />
                <FilterAutoComplete
                    id={`company-${view}`}
                    label={t('dashboard.filters.filter2')}
                    name='company'
                    data={filterData.company}
                    value={company}
                    onChange={(val) => setCompany(val)}
                />
                <SearchInput
                    value={searchString}
                    onChange={setSearchString}
                    placeholder={t('dashboard.filters.search')}
                />
            </div>
            {(year.length > 0 || company.length > 0) &&
                <div className='filters-chips-div'>
                    <div className={`filters-chips-group ${year.length > 0 ? 'show' : 'hide'}`}>
                        {year.length > 0 && <p>{t('dashboard.filters.filter1')}</p>}
                        <div className='filter-chips'>
                            {year.map(el => (
                                <Chip
                                    key={el}
                                    label={el}
                                    variant='filled'
                                    onDelete={() => handleClearFilter(el, 'year')}
                                    color="primary"
                                />
                            ))}
                        </div>
                    </div>
                    <div className={`filters-chips-group ${company.length > 0 ? 'show' : 'hide'}`}>
                        {company.length > 0 && <p>{t('dashboard.filters.filter2')}</p>}
                        <div className='filter-chips'>
                            {company.map(el => (
                                <Chip
                                    key={el}
                                    label={el}
                                    variant='filled'
                                    onDelete={() => handleClearFilter(el, 'company')}
                                    color="primary"
                                />
                            ))}
                        </div>
                    </div>
                </div>
            }
            <div className='mobile-filter-button'>
                <Button
                    aria-label='filters'
                    variant='contained'
                    color='secondary'
                    size='small'
                    startIcon={<FilterList htmlColor='white' />}
                    onClick={() => setShow(false)}
                >
                    <p style={{ color: 'white' }}>{t('filters.hide')}</p>
                </Button>
            </div>
        </div>
    )
}

export default Filters