import React from 'react'
import DialogPrefab from './DialogPrefab'
import Button from '../Buttons/Button'

function InfoDialog({ info, ...props }) {
    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            title={info.title}
            maxWidth='sm'
            fullScreen={window.innerWidth < 960}
        >
            <div style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
                <div style={{ marginBottom: '20px', whiteSpace: 'pre-wrap', letterSpacing: '1px', lineHeight: '20px' }}>
                    <p style={{ color: 'var(--text)', fontSize: '15px', textAlign: 'center' }}>{info.message}</p>
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', gap:'20px' }}>
                    {props.type === "y/n" ? (
                        <>
                            <Button onClick={props.ycb}>
                                Sim
                            </Button>
                            <Button onClick={props.ncb}>
                                Não
                            </Button>
                        </>
                    ) : (
                        <Button onClick={props.ocb}>
                            Ok
                        </Button>
                    )}
                </div>
            </div>
        </DialogPrefab>
    )
}

export default InfoDialog