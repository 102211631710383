import React, { useEffect, useState, useContext } from 'react'
import { useUserInfo } from '../Hooks/useUser'
import useToken from '../Hooks/useToken'
import useGlobalSnackbar from '../Hooks/useGlobalSnackbar'
import { useNavigate } from 'react-router-dom'
import { SocketContext } from '../Context/socket/socket'
import { loginUser } from '../API/Requests'
import Button from '../Components/Buttons/Button'
import { TextField, PasswordTextField } from '../Components/Inputs/TextField/TextField'
import ForgotPassDialog from '../Components/Dialogs/ForgotPassword/ForgotPassDialog'
import { useTranslation } from 'react-i18next'
import CheckboxInput from '../Components/Inputs/Checkbox/Checkbox'

import Logo from '../Assets/Logos/logo_vertical.svg'

import './css/LoginPage.css'

function Login() {
    const [loading, setLoading] = useState(false)
    const [openForgotPass, setOpenForgotPass] = useState(false)

    const { t } = useTranslation()
    const { setUserInfo } = useUserInfo()
    const { setToken } = useToken()
    const { showSnackbar } = useGlobalSnackbar()
    const navigate = useNavigate()
    const socket = useContext(SocketContext)

    useEffect(() => {
        localStorage.removeItem('id')
        localStorage.removeItem('remember_token')
        setToken(null)
        setUserInfo({})
        if (socket.connected) socket.disconnect()
    }, [])

    const handleLogin = (e) => {
        e.preventDefault()
        const email = e.target[0].value;
        const pass = e.target[1].value;
        const rememberMe = e.target[3].checked

        setLoading(true)

        loginUser(email, pass, rememberMe).then(res => {
            setToken({ token: res.headers.key })
            if (res.headers.remember_token) {
                localStorage.setItem('remember_token', res.headers.remember_token)
            }
            localStorage.setItem('id', res.headers.id)
            socket.auth = { uuid: res.headers.id, token: res.headers.key }
            socket.connect()
            setLoading(false)
            navigate('/', { replace: true })
        }, err => {
            console.log(err)
            showSnackbar({ message: err.response?.data ?? t('errors.timeout.description'), variant: "error" })
            setLoading(false)
        })
    }

    return (
        <div className='login-page-main-div'>
            <div className='login-page-logo-div'>
                <img alt='logo' src={Logo} className='login-page-logo' />
            </div>
            <form className='login-page-form' onSubmit={handleLogin}>
                <div className='login-textfield'>
                    <TextField
                        autoFocus={true}
                        disabled={loading}
                        name='email'
                        required
                        type='email'
                        placeholder='example@email.com'
                        label={t('inputs.email.title')}
                    />
                </div>
                <div className='login-textfield-pass'>
                    <PasswordTextField
                        disabled={loading}
                        required
                        helperText={t('login.forgotPass')}
                        helperTextAction={() => setOpenForgotPass(true)}
                    />
                </div>
                <Button type={loading ? 'button' : 'submit'} loading={loading}>{t('common.enter')}</Button>
                <CheckboxInput label={t('login.remember_me.label')} tooltip={t('login.remember_me.tooltip')} />
            </form>
            <ForgotPassDialog open={openForgotPass} close={() => setOpenForgotPass(false)} />
        </div>
    )
}

export default Login