import { useModal } from 'mui-modal-provider'
import ErrorDialog from '../Components/Dialogs/ErrorDialog'
import InfoDialog from '../Components/Dialogs/InfoDialog'
import ProjectsTablesMain from '../Components/Dialogs/Tables/ProjectsTablesMain'
import TableDialog from '../Components/Dialogs/Tables/TableDialog'
import Tnc from '../Components/Dialogs/Footer/Tnc'
import Cookies from '../Components/Dialogs/Footer/Cookies'
import Privacy from '../Components/Dialogs/Footer/Privacy'
import Contacts from '../Components/Dialogs/Footer/Contacts'

export default function useDialog() {
    const { showModal } = useModal()

    const openInfoDialog = (
        info = { title: '', message: '' },
        type = 'ok',
        ycb,
        ncb = () => console.log('no ncb provided'),
        ocb = () => console.log('no ocb provided')
    ) => {
        const modal = showModal(InfoDialog, {
            close: () => modal.hide(),
            info: info,
            type: type,
            ncb: () => { modal.hide(); ncb() },
            ocb: () => { modal.hide(); ocb() },
            ycb: () => { modal.hide(); ycb() }
        })
    }

    const openErrDialog = (info, cb = () => console.log('no cb provided')) => {
        const modal = showModal(ErrorDialog, {
            info: info,
            close: () => modal.hide(),
            ocb: () => { modal.hide(); cb() }
        })
    }

    const openTablesDialog = (company) => {
        const modal = showModal(ProjectsTablesMain, {
            company,
            close: () => modal.hide(),
            ocb: () => modal.hide()
        })
    }

    const openTableInfoDialog = (table) => {
        const modal = showModal(TableDialog, {
            table,
            close: () => modal.hide(),
            ocb: () => modal.hide()
        })
    }

    const openTnc = () => {
        const modal = showModal(Tnc, {
            close: () => modal.hide(),
            ocb: () => modal.hide()
        })
    }

    const openPrivacy = () => {
        const modal = showModal(Privacy, {
            close: () => modal.hide(),
            ocb: () => modal.hide()
        })
    }

    const openCookies = () => {
        const modal = showModal(Cookies, {
            close: () => modal.hide(),
            ocb: () => modal.hide()
        })
    }

    const openContacts = () => {
        const modal = showModal(Contacts, {
            close: () => modal.hide(),
            ocb: () => modal.hide()
        })
    }

    return {
        openInfoDialog,
        openErrDialog,
        openTablesDialog,
        openTableInfoDialog,
        openTnc,
        openCookies,
        openContacts,
        openPrivacy
    }
}